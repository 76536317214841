import request from "@/utils/request";

function junkwordsNew(data) {
  return request({
    url: "/show/junk/words/new.diti",
    method: "post",
    data,
  });
}

function junkwordsDel(data) {
  return request({
    url: "/show/junk/words/del.diti",
    method: "post",
    data,
  });
}

export { junkwordsNew, junkwordsDel };
