<template>
  <div>
    <PagingQueryPage
      ref="PagingQueryPage"
      :queryFormState="queryFormState"
      size="middle"
      :bordered="true"
      rowKey="label"
      :columns="columns"
      :pagination="pagination"
      dataUrl="/show/junk/words/filter.diti"
      :requestOptions="requestOptions"
    >
      <a-button type="primary" icon="plus" slot="action" @click="add"
        >新增</a-button
      >
    </PagingQueryPage>
    <a-modal
      :width="520"
      :visible="modalVisible"
      :title="(isAdd ? '新增' : '编辑') + modalTitle"
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="submit"
      @cancel="
        () => {
          modalVisible = false;
        }
      "
    >
      <a-form-model
        ref="modalForm"
        :model="modalFormState"
        :rules="modalRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="垃圾词名称" prop="label">
          <a-input v-model="modalFormState.label" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import PagingQueryPage from "@/components/PagingQueryPage/diti.vue";
import { Modal, notification } from "ant-design-vue";
import { junkwordsNew, junkwordsDel } from "@/apis/junkwords";
import { companyAll } from "@/apis/company";
export default {
  components: { PagingQueryPage },
  data() {
    return {
      queryFormState: {},
      requestOptions: {
        companyId: "0",
      },
      columns: [
        {
          title: "序号",
          dataIndex: "",
          width: 80,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "垃圾词名称",
          dataIndex: "label",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "",
          width: 140,
          align: "center",
          fixed: "right",
          customRender: (record) => {
            return (
              <span class="action-group">
                <a-button
                  class="delBtn"
                  type="link"
                  onClick={() => {
                    this.del(record);
                  }}
                >
                  删除
                </a-button>
              </span>
            );
          },
        },
      ],
      pagination: {},
      modalVisible: false, // 弹窗状态
      isAdd: true, // 是否新增
      modalTitle: "垃圾词", // 弹窗标题
      modalRules: {
        label: [{ required: true, message: "名称必填", trigger: "blur" }],
      }, // 弹窗校验规则
      modalFormState: {
        label: null,
      }, // 弹窗值
      companyList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      companyAll({ numOfPage: "1", rowsInPage: "50", keyword: "" }).then(
        (res) => {
          const {
            code,
            data: { resList },
          } = res?.data;
          if (code === "200") {
            this.companyList = resList;
          }
        }
      );
    },
    add() {
      this.modalVisible = true;
      this.isAdd = true;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = null;
        }
      }
    },
    // edit(record) {
    //   this.modalVisible = true;
    //   this.isAdd = false;
    //   this.modalFormState.permissionId = record.permissionId;
    //   for (const key in this.modalFormState) {
    //     if (Object.hasOwnProperty.call(this.modalFormState, key)) {
    //       this.modalFormState[key] = record[key];
    //     }
    //   }
    // },
    submit() {
      this.$refs["modalForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            junkwordsNew({
              ...this.requestOptions,
              ...this.modalFormState,
              ...this.queryFormState,
            }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "新增成功",
                });
              }
            });
          } else {
            // permissionUpdate({ ...this.modalFormState }).then((res) => {
            //   const { code } = res.data;
            //   if (code === "200") {
            //     this.modalVisible = false;
            //     this.$refs["PagingQueryPage"].query();
            //     notification.success({
            //       message: "编辑成功",
            //     });
            //   }
            // });
          }
        }
      });
    },
    del(record) {
      Modal.confirm({
        title: `确定要删除该${this.modalTitle}吗？`,
        content: "注意：此操作无法撤销！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          junkwordsDel({
            companyId: record.companyId,
            label: record.label,
          }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              this.$refs["PagingQueryPage"].query();
              notification.success({
                message: "删除成功",
              });
            }
          });
        },
      });
    },
  },
};
</script>

<style></style>
